@charset "utf-8";

.button,
.button:focus {
  background: #FCC800;
  border: none;
  transition: .2s ease-out;
  color: #003705;

  &:hover {
    background: #FCC800;
    border: none;
    color: #003705;
    opacity: .8;
  }
}

.btn {
  display: inline-block;
  min-width: 178px;
  height: 52px;
  line-height: 50px;
  margin: 0;
  padding: 0 20px;
  border: none;
  border-radius: 8px;
  background: #FCC800;
  text-align: center;
  transition: .2s ease-out;
  font-size: 18px;
  font-weight: bold;
  color: #003705;

  &:hover,
  &:focus {
    background: #FCC800;
    color: #003705;
    opacity: .8;
  }

  &--L {
    min-width: 270px;
  }
}
