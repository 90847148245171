@charset "utf-8";

#page-top-pdf {
  margin: 0;
  padding: 0;

  .pagePdf {
    position: relative;
    height: 100%;
    page-break-after: always;

    &__content {
      position: relative;
      padding: 30px 30px 80px;

      &--detail {
        .pagePdf__left {
          padding-right: 30%;
        }

        .pagePdf__right {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 30%;
        }
      }
    }
  }

  .pdfFooter {
    position: relative;
    height: 94px;
    margin-top: -94px;

    &__inner {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 98px;
      overflow: hidden;
      background: #003705;
      z-index: 1000;
    }

    &__logo {
      position: absolute;
      top: 34px;
      left: 30px;

      img {
        width: 90px;
      }
    }

    &__detail {
      position: absolute;
      top: 16px;
      left: 150px;
      color: #fff;
    }

    &__nameWrap {
      position: relative;
      line-height: 1;
      margin: 0;
      padding: 2px 0;
      font-size: 38px;
      font-weight: bold;
      color: #fff;

      span {
        display: inline-block;

        &:nth-child(n+2) {
          margin-left: 10px;
        }
      }
    }

    &__name {
      font-size: 38px;
    }

    &__fname {
      margin-right: 18px;
      padding-top: 28px;
      font-size: 16px;
    }

    &__age {
      font-size: 38px;
    }

    &__url {
      position: relative;
      display: inline-block;
      line-height: 1;
      font-size: 13px;
      color: #fff;
    }

    &__info {
      position: absolute;
      top: 12px;
      right: 130px;
      color: #fff;

      ul {
        margin: 0;
        padding: 0;
        text-align: right;
      }

      li {
        line-height: 1;
        margin: 0 0 4px;
        padding: 0;
        font-size: 20px;
        font-weight: bold;
        color: #fff;

        &:first-child {
          margin-bottom: 6px;
        }
      }
    }
  }

  .footerQr {
    position: absolute;
    top: 5px;
    bottom: 5px;
    right: 20px;
    z-index: 1001;
    line-height: 1;
    transform: translateY(-100%);

    &__image {
      line-height: 1;
      width: 88px;
    }
  }

  .imageList {
    margin: 0 auto 30px;

    &__inner {
      position: relative;
    }

    &__main {
      position: relative;
      width: 50%;
      height: 820px;

      img {
        opacity: 0;
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__thumb {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;

      ul {
        position: relative;
      }

      li {
        position: absolute;
        width: 50%;

        > div {
          position: relative;
          margin: 0 0 0 20px;
          height: 400px;
          background-position: top center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        &:nth-child(1) {
          top: 0;
          left: 0;
        }

        &:nth-child(2) {
          top: 0;
          left: 50%;
        }

        &:nth-child(3) {
          top: 400px;
          left: 0;
          > div {
            margin-top: 20px;
          }

          display: none;
        }
        &:nth-child(4) {
          top: 400px;
          left: 50%;
          > div {
            margin-top: 20px;
          }

          display: none;
        }
      }

      img {
        display: none;
        width: 100%;
        opacity: 0;
      }
    }

    &__info {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
    }
  }

  .movieBlock {
    margin: 10px 0 0;
    padding: 0 30px 0 54px;

    &__heading {
      position: relative;
      margin: 0 0 6px;

      &:before {
        content: "";
        position: absolute;
        left: 24px;
        right: 0;
        bottom: 5px;
        height: 1px;
        background: #003705;
      }

      img {
        max-width: 100px;
      }
    }

    &__inner {
      margin-left: 23px;
      font-size: 18px;

      li {
        line-height: 1.25;
        margin: 1px 0 0;
        padding: 0;

        a {
          position: relative;
          display: inline-block;
          color: #000;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #000;
          }
        }
      }
    }
  }

  .spec {
    margin: 20px 0 0;
    padding: 0 30px 0 54px;

    &__heading {
      position: relative;
      margin: 0 0 10px;

      &:before {
        content: "";
        position: absolute;
        left: 24px;
        right: 0;
        bottom: 5px;
        height: 1px;
        background: #003705;
      }

      img {
        max-width: 70px;
      }
    }

    &__inner {
      margin-left: 23px;
      font-size: 18px;
    }

    &__inlineBlock {
      overflow: hidden;

      > div {
        float: left;
      }

      span {
        &:first-child {
          display: inline-block;
          line-height: 1.25;
          margin: 0 10px 0 0;
          padding: 0 10px;
          background: #000;
          color: #fff;
          text-align: center;
        }
      }

      > div {
        line-height: 1.25;

        &:nth-child(2n) {
          padding-top: 1px;
        }

        &:nth-child(2n+3) {
          margin-left: 12px;
        }
      }

      & + .spec__inlineBlock {
        margin-top: 10px;
      }
    }

    &__listBlock {
      display: flex;
      margin-top: 10px;
      font-size: 18px;

      > div {
        &:nth-child(1) {
          margin-right: 10px;
        }
        &:nth-child(2) {
          flex: 2;
          line-height: 1;
          margin-top: -1px;
        }
      }

      span {
        display: inline-block;
        line-height: 1.25;
        min-width: 140px;
        padding: 0 20px;
        background: #000;
        color: #fff;
        text-align: center;
      }
    }
  }

  .works {
    margin: 10px 0 0;
    padding: 0 30px 0 54px;

    &__heading {
      position: relative;
      margin: 0 0 10px;

      &:before {
        content: "";
        position: absolute;
        left: 24px;
        right: 0;
        bottom: 5px;
        height: 1px;
        background: #003705;
      }

      img {
        max-width: 80px;
      }
    }

    &__inner {
      margin-left: 23px;
      font-size: 18px;
    }

    &__name {
      display: inline-block;
      line-height: 1.25;
      min-width: 120px;
      padding: 0 20px;
      background: #000;
      color: #fff;
      text-align: center;

      & + span {
        display: inline-block;
        margin-top: -2px;
      }
    }

    .spec__hyphen {
      margin: 0 5px;
    }
  }
}
