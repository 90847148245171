@charset "utf-8";

.uploadImage {
  position: relative;
  padding: 0 0 15px;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: #eee;
  }

  &--noborder {
    padding-bottom: 0;
    &:before {
      display: none;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    margin: 0 -10px;
  }

  &__file {
    flex: 1;
    margin: 0 10px;

    &.-hide {
      display: none;
    }

    label {
      display: none;
    }

    input[type=file] {
      margin-bottom: 0;
    }
  }

  &__fileImage {
    &.-hide {
      display: none;
    }
  }

  &__image {
    flex: 1;
    margin: 0 10px;

    &.-hide {
      display: none;
    }

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  &__sort {
    margin: 0 10px;

    .input {
      display: flex;
      align-items: center;
    }

    label {
      margin: 0 20px 0 0;
      font-size: 19px;
      font-weight: normal;
      white-space: nowrap;
    }

    input {
      width: 86px;
      margin: 0;
    }
  }

  &__control {
    padding: 15px 0 0;
    text-align: right;

    .button {
      margin: 0;
      padding: 0;
      width: 100px;
      border: 1px solid #d1f1f1;
      color: $c-text;
      &:hover {
        border: 1px solid #d1f1f1;
      }
      &.-hide {
        display: none;
      }
    }
  }
}
