@charset "utf-8";

.login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #003705;

  &__inner {
    position: absolute;
    top: 50%;
    left: calc(50% - 20px);
    right: 0;
    transform: translate(-50%, -50%);
    width: calc(100vw - 40px);
    max-width: 748px;
    margin-left: 20px;
    padding: 100px 20px 45px;
    text-align: center;
    backface-visibility: hidden;

    label,
    input,
    fieldset {
      margin: 0;
    }

    fieldset {
      display: block;
    }
  }

  &__icon {
    margin-bottom: 60px;
    text-align: center;

    img {
      width: 100%;
      max-width: 208px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 70px;
    padding: 0 134px 0 20px;
    text-align: left;

    label {
      min-width: 5em;
      margin-right: 1em;
      font-size: 18px;
      font-weight: bold;
      text-align: right;
      color: #fff;
    }

    input {
      flex: 1;
      width: 100%;
      height: 52px;
      line-height: 52px;
      border-radius: 0;
      font-size: 18px;
      font-weight: bold;
      background: #fff;
    }
  }

  &__error {
    position: relative;
    display: none;
    height: 0;

    span {
      position: absolute;
      display: block;
      top: -40px;
      left: 0;
      right: 0;
      font-size: 19px;
      color: #FF0000;
      text-align: center;
    }

    &.-show {
      display: block;
    }
  }

  &__btn {
    position: relative;
    display: inline-block;

    &:before {
      content: "\ea77";
      position: absolute;
      font-family: 'Material Icons';
      top: 50%;
      right: 22px;
      font-size: 24px;
      color: #003705;
      transform: translateY(-50%);
      backface-visibility: hidden;
      z-index: 100;
      pointer-events: none;
    }
  }
}
