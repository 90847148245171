@charset "utf-8";

.pageNav {
  margin: 0 0 15px;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  li {
    display: inline-block;
    margin-left: 30px;
  }

  a {
    position: relative;
    display: inline-block;
    padding-right: 20px;

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 9px);
      right: 0;
      background-color: #707070;
      width: 14px;
      height: 18px;
      clip-path: polygon(0 0, 0 100%, 100% 50%);
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2px;
      height: 1px;
      background: #707070;
    }
  }
}
