@charset "utf-8";

body.-modalOpen {
  overflow: hidden;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  opacity: 0;
  z-index: 1003;
  transition: opacity .4s ease .2s;

  &.-visible {
    display: block;
  }

  &.-show {
    opacity: 1;
    transition: opacity .4s ease 0s;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &__inner {
    display: none;
    position: fixed;
    overflow: hidden;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    max-height: 100%;
    background: none;
    padding: 54px;
    transform: translate(-50%, -50%);
    pointer-events: none;

    &.-show {
      display: block;
    }
  }

  &__content {
    overflow: auto;
    max-height: calc(100vh - 108px);
    background: #fff;
    padding: 40px;
    pointer-events: auto;
  }

  #searchModal {
    max-width: 928px;
    margin-left: auto;
    margin-right: auto;
  }

  .searchModal {
    position: relative;

    &__checkbox {
      position: absolute;
      top: 40px;
      left: 40px;
      right: 0;
      width: 30px;
      height: 30px;
      margin: 0;
      padding: 0;
      z-index: 11;

      input {
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        opacity: 0;
        cursor: pointer;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        border: 1px solid #707070;
        background: #fff;
        pointer-events: none;
      }

      &:after {
        display: none;
        content: '';
        position: absolute;
        top: 8px;
        left: 7px;
        width: 18px;
        height: 8px;
        border-left: 2px solid #707070;
        border-bottom: 2px solid #707070;
        transform: rotate(-45deg);
        pointer-events: none;
      }

      &.-check {
        &:before {
          background: #FCC800;
        }
        &:after {
          display: block;
        }
      }
    }
  }

  #searchAppearanceModal {
    max-width: 928px;
    margin-left: auto;
    margin-right: auto;
  }

  .searchAppearanceModal {
    position: relative;
  }

  #addTalentModal {
    max-width: 600px;
    border-radius: 5px;
  }

  .addTalentModal {
    padding: 30px;
    background: #fff;
    pointer-events: auto;

    input,
    select {
      height: 52px;
      margin: 0;
    }

    &__heading {
      margin: 0 0 20px;
      font-size: 19px;
      font-weight: bold;
      text-align: center;
    }
    &__search {
      display: flex;
      align-items: center;
      margin: 0 0 15px;

      input {
        flex: 1;
      }
      a.btn {
        margin: 0 0 0 15px;
      }
    }

    select {
      display: none;

      appearance: none;
      &::-ms-expand {
        display: none;
      }

      height: 52px;
      margin: 0;
      padding-right: 3rem;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23d1d1d1" d="M0,0l6,8l6-8"/></svg>') center right no-repeat;
      font-size: 19px;

      &:focus {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23707070" d="M0,0l6,8l6-8"/></svg>');
      }

      &.-show {
        display: block;
      }
    }

    &__submit {
      display: none;
      max-width: 270px;
      margin: 15px auto 0;
      font-size: 16px;
      &.-show {
        display: block;
      }
    }
  }

  #listCheckModal {
    max-width: 928px;
    margin-left: auto;
    margin-right: auto;
  }

  .listCheckModal {
    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__heading {
      margin-top: -20px;
      font-size: 30px;
      font-weight: bold;
    }

    &__copyBtn {
      position: relative;
      margin-top: -20px;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 28px;
        width: 16px;
        height: 18px;
        background: center center url(../img/icon-file-copy.svg) no-repeat;
        background-size: 16px 18px;
        transform: translateY(-50%);
        backface-visibility: hidden;
        z-index: 100;
        pointer-events: none;
        transition: opacity .4s ease;
      }

      &:hover:before {
        opacity: .7;
      }

      .btn {
        padding-right: 38px;
      }
    }

    &__inner {
      margin: 30px 0 0;
      pre {
        line-height: 1.68;
        margin: 0;
        padding: 20px 30px;
        border: 1px solid #707070;
        background: #fff;
        font-size: 16px;
      }
    }
  }

  &__closeBtn {
    text-align: center;

    .btn {
      line-height: 1;
      height: auto;
      min-width: 136px;
      margin: 0 auto;
      padding: 6px 10px;
      background: #fff;
      border: 1px solid #003705;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #003705;
      transition: .2s ease-out;

      &:hover,
      &:focus {
        background: #fff;
        color: #003705;
        opacity: .8;
      }
    }
  }

  &__closeIcon {
    position: absolute;
    top: 62px;
    right: 62px;
    width: 44px;
    height: 44px;
    background: #fff;
    border: 1px solid #707070;
    border-radius: 90%;
    transform: translate(50%, -50%);
    pointer-events: auto;
    transition: opacity .4s ease;
    cursor: pointer;

    &:hover {
      background: #fff;
      opacity: .9;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 19px;
      height: 2px;
      background: #707070;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    span {
      display: none;
    }
  }
}
