@charset "utf-8";

#page-top-detail {
  margin: 0;
  padding: 0;

  .fontEn {
    @include fontEn;
  }

  .viewContent {
    max-width: 1080px;
    margin: 0 auto;
    padding: 40px 40px 70px;
  }

  .imageList {
    margin: 30px auto;

    &__inner {
      display: -webkit-box;
      display: flex;
    }

    &__main {
      text-align: center;
      flex: 1;
    }

    &__thumb {
      width: 370px;
      margin-left: 20px;

      ul {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 0 0;
        li {
          width: 170px;
          margin: 0 10px 20px;
        }
        a {
          position: relative;
          transition: opacity .4s ease;
          &:hover {
            opacity: .7;
          }
          &.-active {
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: 3px solid #FCC800;
              pointer-events: none;
            }
          }
        }
      }

      img {
        width: 100%;
      }
    }

    &__download {
      margin: 20px 0 0;
    }

    &__downloadBtn {
      line-height: 1;
      height: auto;
      margin: 0;
      padding: 6px 20px;
      background: #fff;
      border: 1px solid #003705;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #003705;
      transition: .2s ease-out;

      &:hover,
      &:focus {
        background: #fff;
        color: #003705;
        opacity: .8;
      }
    }

    &__alert {
      margin-top: 15px;
      font-size: 16px;
      color: #C30000;
    }

    &--pdf {
      ul {
        overflow: hidden;
        margin-top: -20px;
        margin-right: -20px;
      }
      li {
        display: inline-block;
        float: left;
        margin: 20px 20px 0 0;
      }
      img {
        max-width: 100%;
      }
    }
  }

  .movieBlock {
    margin: 40px auto;

    &__inner {
      max-width: 670px;
      margin: 0 auto;
      font-size: 16px;

      > p {
        margin: 0 0 5px;
        font-weight: bold;
      }
    }

    &__iframe {
      position: relative;
      width: 100%;
      height: 0;
      margin: 0 auto 0 0;
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    &--list {
      display: none;

      > p {
        margin: 0 0 5px;
        font-weight: bold;
      }

      a {
        font-size: 20px;
        text-decoration: underline;
      }
    }
  }

  .spec {
    margin: 50px 0 0;

    &__heading {
      position: relative;
      margin: 0 0 30px;

      &:before {
        content: "";
        position: absolute;
        left: 24px;
        right: 0;
        bottom: 5px;
        height: 1px;
        background: #003705;
      }
    }

    &__nameBlock {
      display: flex;
      align-items: center;
      line-height: 1;
      margin: 0 0 30px 0;
    }

    &__name {
      margin: 0 30px 0 0;
      font-size: 30px;
      font-weight: bold;
    }

    &__pdfLink {
      line-height: 1;
      padding: 6px 20px;
      background: #fff;
      border: 1px solid #003705;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #003705;

      span {
        position: relative;
        display: inline-block;
        padding-right: 20px;

        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 8px);
          right: 0;
          width: 16px;
          height: 16px;
          background: center center url(../img/icon-btnlist-pdf.svg) no-repeat;
          background-size: 16px 16px;
        }
      }
    }

    &__inlineBlock {
      display: flex;
      align-items: center;

      span:first-child {
        display: inline-block;
        line-height: 1;
        margin: 0 10px 0 0;
        padding: 4px 5px;
        background: #707070;
        font-size: 14px;
        color: #fff;
        text-align: center;
      }

      > div {
        line-height: 1;
        font-size: 16px;

        &:nth-child(2n) {
          padding-top: 2px;
        }

        &:nth-child(2n+3) {
          margin-left: 30px;
        }
      }

      & + .spec__inlineBlock {
        margin-top: 20px;
      }
    }

    &__listBlock {
      display: flex;
      margin-top: 20px;

      & + .spec__listBlock {
        margin-top: 20px;
      }

      > div {
        &:nth-child(1) {
          margin-right: 10px;
        }
        &:nth-child(2) {
          flex: 2;
          line-height: 1.25;
          padding: 2px 0;
          font-size: 16px;
        }
      }

      span {
        display: inline-block;
        line-height: 1;
        width: 100px;
        padding: 4px;
        background: #707070;
        font-size: 14px;
        color: #fff;
        text-align: center;
      }
    }
  }

  .works {
    margin: 50px 0 0;

    &__heading {
      position: relative;
      margin: 0 0 30px;

      &:before {
        content: "";
        position: absolute;
        left: 24px;
        right: 0;
        bottom: 5px;
        height: 1px;
        background: #003705;
      }
    }

    table {
      width: 100%;
      border: none;

      th, td {
        border: none;
        padding: 0;
      }

      th {
        width: 100px;
        padding-top: 8px;
        text-align: center;

        span {
          display: inline-block;
          line-height: 1;
          width: 100px;
          padding: 4px;
          background: #707070;
          font-size: 14px;
          color: #fff;
          text-align: center;
        }
      }

      td {
        font-size: 16px;
        padding: 2px 10px;

        > div {
          display: flex;
          flex-wrap: wrap;

          > div {
            &:nth-child(n+2) {
              margin-left: 10px;
            }
          }
        }
        span {
          display: inline-block;
        }
        .spec__hyphen {
          margin: 0 5px;
        }
      }
    }
  }
}
