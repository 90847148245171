@charset "utf-8";

.form {
  input,
  select,
  textarea {
    border-color: $c-text;
  }

  legend {
    margin: 0 auto 40px;
    font-size: 24px;
  }

  &__submitBtn {
    display: block;
    min-width: 110px;
    margin: 50px auto;
    text-align: center;
    font-size: 16px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    &.-fixed {
      position: fixed;
      left: 300px;
      right: 0;
      bottom: 0;
      margin: 0;
    }

    &--search {
      padding: 15px 0;
      background: rgba(#fff, .8);
      z-index: 10;

      & .form__submitBtnInner {
        &:before {
          content: "\e8b6";
          position: absolute;
          font-family: 'Material Icons';
          top: 50%;
          right: 38px;
          font-size: 28px;
          color: #003705;
          transform: translateY(-50%);
          backface-visibility: hidden;
          z-index: 100;
          pointer-events: none;
          transition: opacity .4s ease;
        }

        &:hover:before {
          opacity: .7;
        }
      }
    }
  }

  &__submitBtnInner {
    position: relative;
    display: inline-block;
  }
}

.formBlock {
  display: flex;
  align-items: center;
  width: calc(100% + 30px);
  margin: 0 -15px;

  & + & {
    margin-top: 40px;
  }

  input {
    &[type=date],
    &[type=text],
    &[type=tel],
    &[type=number],
    &[type=email] {
      height: 52px;
      margin: 0;
      padding: 15px;
      font-size: 19px;
      border-radius: 0;
      border: 1px solid #707070;
    }
  }

  .checkbox {
    label {
      margin: 0;
      font-weight: normal;
    }

    &--inline {
      display: inline-block;
    }
  }

  &__header {
    width: 200px;
    margin: 0 15px;
    font-size: 18px;
    font-weight: bold;
    text-align: right;

    span {
      position: relative;
      &.require {
        &:before {
          content: "※";
          line-height: 1;
          position: absolute;
          top: -0.8em;
          right: -0.6em;
          color: #FF0000;
          font-size: 16px;
        }
      }
    }
  }

  &__body {
    flex: 1;
    margin: 0 10px;

    input[type=text],
    input[type=tel],
    input[type=number],
    textarea {
      padding: 15px;
      vertical-align: top;

      &:last-child {
        margin-bottom: 0;
      }
    }

    input[type=checkbox] {
      margin-right: 1rem;
    }

    textarea {
      min-height: 262px;
      font-size: 19px;
    }

    .radio {
      display: flex;
      align-items: center;
      margin: 0 -20px;

      label {
        position: relative;
        line-height: 1;
        margin: 0 20px;
        padding-left: 50px;
        font-size: 16px;
        font-weight: normal;
        cursor: pointer;

        &:first-of-type {
          margin: 0 20px;
        }

        input {
          display: none;
        }

        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 18px);
          left: 0;
          width: 36px;
          height: 36px;
          border: 1px solid #707070;
          border-radius: 90%;
          pointer-events: none;
        }

        &:after {
          display: none;
          content: "";
          position: absolute;
          top: calc(50% - 14px);
          left: 4px;
          width: 28px;
          height: 28px;
          background: #FCC800;
          border-radius: 90%;
          pointer-events: none;
        }

        &.-check {
          &:after {
            display: block;
          }
        }
      }
    }

    .checkbox {
      position: relative;

      label {
        position: relative;
        padding-left: 35px;
        cursor: pointer;

        input {
          display: none;
        }

        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 12px);
          left: 0;
          width: 24px;
          height: 24px;
          border: 1px solid #707070;
          pointer-events: none;
        }

        &:after {
          display: none;
          content: '';
          position: absolute;
          top: calc(50% - 4px);
          left: 7px;
          width: 12px;
          height: 6px;
          border-left: 2px solid #707070;
          border-bottom: 2px solid #707070;
          transform: rotate(-45deg);
          pointer-events: none;
        }

        &.-check {
          &:before {
            background: #FCC800;
          }
          &:after {
            display: block;
          }
        }
      }
    }
  }

  &--date {
    input {
      max-width: 200px;
    }
  }

  &--sns {
    .formBlock__body {
      display: flex;
      align-items: center;
    }

    .input {
      display: flex;
      align-items: center;

      &:first-child {
        input {
          width: 160px;
        }
      }

      &:last-child {
        flex: 1;
        margin-left: 30px;
      }
    }

    label {
      margin: 0 20px 0 0;
      font-size: 19px;
      font-weight: normal;
      white-space: nowrap;
    }
  }

  &--zipcode {
    .input {
      display: flex;
    }
    .zipBlock {
      display: flex;
      align-items: center;

      input {
        max-width: 140px;
      }

      span {
        display: block;
        margin: 0 20px;
      }

      .btn {
        display: block;
        min-width: 130px;
        max-width: 130px;
        margin: 0 0 0 20px;
        padding: 0;
      }
    }
  }

  &--prefecture {
    .input {
      position: relative;
      width: 200px;
    }

    select {
      appearance: none;
      &::-ms-expand {
        display: none;
      }

      height: 52px;
      margin: 0;
      padding-right: 3rem;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23d1d1d1" d="M0,0l6,8l6-8"/></svg>') center right no-repeat;
      font-size: 19px;

      &:focus {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23707070" d="M0,0l6,8l6-8"/></svg>');
      }
    }
  }

  &__checkboxInline {
    .checkbox {
      display: inline-block;
      &:nth-of-type(n+2) {
        margin-left: 40px;
      }
    }
  }

  &__between {
    display: flex;
    align-items: center;
    > div {
      &:nth-child(1),
      &:nth-child(3) {
        flex: 1;
      }
      &:nth-child(2) {
        margin: 0 20px;
      }
    }
  }

  &__tagBlock {
    overflow: auto;
    height: 188px;
    border: 1px solid #707070;
    padding: 18px;

    .checkbox {
      label {
        font-size: 16px;
      }
      & + .checkbox {
        margin-top: 8px;
      }
    }
  }

  &--select {
    .input {
      position: relative;
    }

    select {
      appearance: none;
      &::-ms-expand {
        display: none;
      }

      height: 52px;
      margin: 0;
      padding-right: 3rem;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23d1d1d1" d="M0,0l6,8l6-8"/></svg>') center right no-repeat;
      font-size: 19px;

      &:focus {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23707070" d="M0,0l6,8l6-8"/></svg>');
      }
    }
  }

  // 出演タレント
  &--talent {
    align-items: flex-start;

    .formBlock__header {
      line-height: 52px;
    }

    .formBlock__body {
      display: flex;
      align-items: flex-end;
      padding-bottom: 20px;
      border-bottom: 1px solid #d1d1d1;

      > button {
        min-width: 100px;
        margin: 0 0 6px 20px;
        padding: 0;
        font-size: 14px;
        background: #fff;
        color: $c-text;
        border: 1px solid #d1d1d1;

        &.-hide {
          display: none;
        }

        &:hover {
          background: #fff;
          color: $c-text;
          border: 1px solid #d1d1d1;
        }
      }
    }

    & + .formBlock--talent {
      margin-top: 20px;
    }

    & + .formBlock {
      margin-top: 20px;
    }

    & + .formBlock__talent {
      .formBlock__body {
        padding-bottom: 0;
      }

      .btn {
        margin-top: -20px;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
  &__talent {
    flex: 1;

    .btn {
      display: block;
      max-width: 270px;
      margin: 0 auto;
    }

    &.-hide {
      .formBlock__talentRow {
        display: none;
      }
    }
  }
  &__talentRow {
    display: flex;
    align-items: center;

    & + .formBlock__talentRow {
      margin-top: 20px;
    }

    div,
    span,
    input {
      font-size: 19px;
    }

    div {
      margin: 0 50px 0 0;
    }

    span {
      display: inline-block;
      margin: 0 20px 0 0;
    }

    input {
      flex: 1;
    }
  }
}

.searchForm {
  &__heading {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 20px;
    background: #F2F2F2;
    border: 1px solid #707070;
    text-align: center;
    color: $c-text;
    &:hover {
      color: $c-text;
    }
    &:focus {
      color: $c-text;
    }

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 8px);
      right: 20px;
      background-color: #707070;
      width: 18px;
      height: 14px;
      clip-path: polygon(0 0, 100% 0%, 50% 100%);
    }

    & + form {
      padding: 50px 100px 0 20px;;
      transition: opacity .4s ease;
      opacity: 0;
    }

    &.-active {
      &:before {
        clip-path: polygon(0 100%, 100% 100%, 50% 0%);
      }

      & + form {
        opacity: 1;
      }
    }
  }
}

