@charset "utf-8";

#page-top-view {
  margin: 0;
  padding: 0;

  .sideMenu + .contents__main {
    padding: 0;
  }

  .viewContent {
    max-width: 820px;
    margin: 0 auto;
    padding: 100px 40px 70px;

    .modal & {
      max-width: inherit;
      margin: 0;
      padding: 0;
    }
  }

  .modal & {
    max-width: inherit;
    margin: 0;
    padding: 0 0 50px;
  }

  .intro {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto 30px;

    .btnList {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin: 0 0 20px;

      a {
        &:nth-child(n+2) {
          margin-left: 8px;
        }
      }

      &__link {
        line-height: 1;
        padding: 6px 20px;
        background: #fff;
        border: 1px solid #003705;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        color: #003705;

        span {
          position: relative;
          display: inline-block;
          padding-right: 20px;
        }

        &--edit {
          padding: 6px 15px;
          border-color: #FCC800;
          background: #FCC800;

          span {
            &:before {
              content: "";
              position: absolute;
              top: calc(50% - 8px);
              right: 0;
              width: 16px;
              height: 16px;
              background: center center url(../img/icon-btnlist-edit.svg) no-repeat;
              background-size: 16px 16px;
            }
          }
        }

        &--view {
          span {
            padding-right: 17px;

            &:before {
              content: "";
              position: absolute;
              top: calc(50% - 8px);
              right: 0;
              width: 13px;
              height: 16px;
              background: center center url(../img/icon-btnlist-view.svg) no-repeat;
              background-size: 13px 16px;
            }
          }
        }

        &--detail {
          span {
            &:before {
              content: "";
              position: absolute;
              top: calc(50% - 6px);
              right: 0;
              width: 16px;
              height: 13px;
              background: center center url(../img/icon-btnlist-detail.svg) no-repeat;
              background-size: 16px 13px;
            }
          }
        }

        &--pdf {
          span {
            &:before {
              content: "";
              position: absolute;
              top: calc(50% - 8px);
              right: 0;
              width: 16px;
              height: 16px;
              background: center center url(../img/icon-btnlist-pdf.svg) no-repeat;
              background-size: 16px 16px;
            }
          }
        }
      }
    }

    &__left {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
    }

    &__heading {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
      align-items: flex-end;
      flex-wrap: wrap;
      line-height: 1;
      margin: 0;
      font-size: 30px;
      font-weight: bold;

      span {
        display: block;
        margin-right: 20px;
        margin-bottom: 10px;
      }

      div {
        display: block;
        margin-bottom: 10px;
        font-size: 20px;
      }
    }

    &__detail {
      font-size: 16px;

      p {
        display: flex;
        align-items: center;
        margin: 0 0 5px;
        font-size: 16px;

        span {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    &__age,
    &__gender {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      white-space: nowrap;
    }
    &__gender {
      margin-left: 20px;
    }

    &__detailHeading {
      display: inline-block;
      line-height: 1;
      padding: 3px 10px;
      background: #707070;
      color: #fff;
      white-space: nowrap;

      & + span + .intro__detailHeading {
        margin-left: 5px;
      }
    }

    &__right {
      max-width: 240px;
      text-align: right;

      p:first-child {
        position: relative;
        line-height: 1;
        display: inline-block;
        margin-bottom: 10px;
        padding-right: 24px;
        font-size: 25px;
        text-align: right;

        &:before {
          position: absolute;
          content: "\e40c";
          top: 50%;
          right: 0;
          font-family: 'Material Icons';
          font-size: 22px;
          color: #707070;
          transform: translateY(-50%);
        }

        &.-none {
          &:before {
            content: "\e5cd";
          }
        }
      }

      ul {
        margin: 5px 0 15px;
        display: inline-block;
        font-size: 16px;
        text-align: left;

        li {
          margin: 0;
        }
      }
    }
  }

  .imageList {
    margin: 30px auto;

    &__inner {
      display: -webkit-box;
      display: flex;
    }

    &__main {
      text-align: center;
      flex: 1;
    }

    &__thumb {
      width: 280px;
      margin-left: 20px;

      ul {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 0 0;
        li {
          width: 125px;
          margin: 0 10px 20px;
        }
        a {
          position: relative;
          transition: opacity .4s ease;
          &:hover {
            opacity: .7;
          }
          &.-active {
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: 3px solid #FCC800;
              pointer-events: none;
            }
          }
        }
      }

      img {
        width: 100%;
      }
    }

    &__download {
      margin: 20px 0 0;
    }

    &__downloadBtn {
      line-height: 1;
      height: auto;
      margin: 0;
      padding: 6px 20px;
      background: #fff;
      border: 1px solid #003705;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #003705;
      transition: .2s ease-out;

      &:hover,
      &:focus {
        background: #fff;
        color: #003705;
        opacity: .8;
      }
    }

    &__alert {
      margin-top: 15px;
      font-size: 16px;
      color: #C30000;
    }

    &--pdf {
      ul {
        overflow: hidden;
        margin-top: -20px;
        margin-right: -20px;
      }
      li {
        display: inline-block;
        float: left;
        margin: 20px 20px 0 0;
      }
      img {
        max-width: 100%;
      }
    }
  }

  .movieBlock {
    margin: 40px auto;

    &__inner {
      max-width: 560px;
      font-size: 16px;

      > p {
        margin: 0 0 5px;
        font-weight: bold;
      }
    }

    &__heading {
      span {
        display: inline-block;
        line-height: 1;
        padding: 3px 10px;
        background: #707070;
        color: #fff;
        white-space: nowrap;
      }
    }

    &__iframe {
      position: relative;
      width: 100%;
      height: 0;
      margin: 0 auto 0 0;
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    &--list {
      display: none;

      > p {
        margin: 0 0 5px;
        font-weight: bold;
      }

      a {
        font-size: 20px;
        text-decoration: underline;
      }
    }
  }

  .snsBlock {
    margin: 40px auto;

    > p {
      margin: 0 0 5px;
      font-weight: bold;
    }

    &__list {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
      align-items: flex-end;
      line-height: 1;

      &:nth-child(n+2) {
        margin-top: 10px;
      }

      div {
        font-size: 20px;
        a {
          text-decoration: underline;
        }
      }
    }
  }

  .detailTable {
    &__heading {
      margin: 100px 0 10px;
      font-size: 30px;
      font-weight: bold;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #707070;

      th, td {
        padding: 15px;
        border: 1px solid #707070;
        font-size: 16px;
      }

      th {
        width: 252px;
        background: #F2F2F2;
      }

      td {
        width: calc(100% - 252px);
      }
    }
  }
}
