@charset "utf-8";

@media print {
  .sideMenu {
    display: none;

    & + .contents__main {
      margin: 0;
      padding: 20px;
    }
  }

  #modal {
    display: none;
  }

  #page-top {
    padding: 0;

    .searchForm { display: none; }
    .item__check { display: none; }
    .listMoreBtn { display: none; }
    .fixedSelectedList { display: none; }

    .listHead { margin-top: 0; }

    .list {
      display: block;
      margin-top: 20px;
    }

    .item {
      break-inside: avoid;
    }

    .list--type-box .item {
      float: left;
    }

    .list--type-box .item__image {
      height: 250px;
      padding-bottom: 0;
    }
  }

  #page-appearance {
    .searchForm { display: none; }

    .listHead { margin-top: 0; }

    .list .item {
      break-inside: avoid;
    }
  }
}
