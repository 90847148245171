@charset "utf-8";

.pageHeader {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  background: #003705;

  &__logo {
    margin-right: 20px;
    img {
      width: 66px;
    }
  }

  &__detail {
    flex: 1;
    color: #fff;
  }

  &__name {
    line-height: 1;
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 10px 0 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin: 0 8px 0 0;

      span {
        @include fontEn;
        position: relative;
        line-height: 1;
        display: inline-block;
        padding: 2px 4px;
        font-size: 16px;
        color: #fff;
        white-space: nowrap;

        &:nth-child(1) {
          margin-right: 2px;
          background: #fff;
          font-size: 14px;
          color: #003705;
        }
      }
    }
  }

  &__info {
    margin-left: auto;
    color: #fff;

    ul {
      margin: 0 0 10px;
      padding: 0;
      text-align: right;
    }

    li {
      @include fontEn;
      line-height: 1;
      margin: 0 0 5px;
      padding: 0;
      font-size: 10px;
      font-weight: bold;
      color: #fff;
    }

    p {
      @include fontEn;
      margin: 5px 0 0;
      padding: 0;
      font-size: 10px;
      color: #fff;
      transform: scale(.8);
      transform-origin: right top;
    }
  }
}
