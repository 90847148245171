@charset "utf-8";

body {
  line-height: $root-line-height;
  background: $c-white;
  font-family: $font-family;
  font-size: $root-font-size;
  font-weight: normal;
  color: $c-text;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  @media #{$mq-sp} {
    line-height: $root-line-height-sp;
    font-size: $root-font-size-sp;
  }
}

main {
  display: block;
}

select::-ms-expand {
  display: none;
}

a {
  display: inline-block;
  jertical-align: top;
  color: $c-link;
  text-decoration: none;
  transition: opacity .4s ease-out;
  &:hover {
    opacity: .7;
    color: $c-link;
  }
  &:focus {
    color: $c-link;
  }
}

span {
  display: inline-block;
  vertical-align: top;
}

img {
  vertical-align: top;
}

ul, ol {
  list-style: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::placeholder {
	color: $c-text;
}
:-ms-input-placeholder {
	color: $c-text;
}
::-ms-input-placeholder {
	color: $c-text;
}

