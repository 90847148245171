@charset "utf-8";

#page-appearance-view {
  margin: 0;
  padding: 0;

  .sideMenu + .contents__main {
    padding: 0;
  }

  .viewContent {
    max-width: 820px;
    margin: 0 auto;
    padding: 100px 40px 70px;

    .modal & {
      max-width: inherit;
      margin: 0;
      padding: 0;
    }
  }

  .modal & {
    max-width: inherit;
    margin: 0;
    padding: 0 0 50px;
  }

  .intro {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto 30px;

    .btnList {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin: 0 0 20px;

      a {
        &:nth-child(n+2) {
          margin-left: 8px;
        }
      }

      &__link {
        line-height: 1;
        padding: 6px 20px;
        background: #fff;
        border: 1px solid #003705;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        color: #003705;

        span {
          position: relative;
          display: inline-block;
          padding-right: 20px;
        }

        &--edit {
          padding: 6px 15px;
          border-color: #FCC800;
          background: #FCC800;

          span {
            &:before {
              content: "";
              position: absolute;
              top: calc(50% - 8px);
              right: 0;
              width: 16px;
              height: 16px;
              background: center center url(../img/icon-btnlist-edit.svg) no-repeat;
              background-size: 16px 16px;
            }
          }
        }

        &--view {
          span {
            padding-right: 17px;

            &:before {
              content: "";
              position: absolute;
              top: calc(50% - 8px);
              right: 0;
              width: 13px;
              height: 16px;
              background: center center url(../img/icon-btnlist-view.svg) no-repeat;
              background-size: 13px 16px;
            }
          }
        }

        &--detail {
          span {
            &:before {
              content: "";
              position: absolute;
              top: calc(50% - 6px);
              right: 0;
              width: 16px;
              height: 13px;
              background: center center url(../img/icon-btnlist-detail.svg) no-repeat;
              background-size: 16px 13px;
            }
          }
        }
      }
    }

    &__left {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
    }

    &__heading {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
      align-items: flex-end;
      flex-wrap: wrap;
      line-height: 1;
      margin: 0;
      font-size: 30px;
      font-weight: bold;

      span {
        display: block;
        margin-right: 20px;
        margin-bottom: 10px;
      }

      div {
        display: block;
        margin-bottom: 10px;
        font-size: 20px;
      }
    }

    &__detail {
      font-size: 16px;
    }

    &__right {
      max-width: 240px;
      text-align: right;

      p:first-child {
        line-height: 1;
        margin-bottom: 10px;
        font-size: 25px;
        text-align: right;
      }

      ul {
        margin: 5px 0 15px;
        display: inline-block;
        font-size: 16px;
        text-align: left;

        li {
          margin: 0;
        }
      }
    }
  }

  .imageList {
    margin: 30px auto;

    &__inner {
      display: -webkit-box;
      display: flex;
    }

    &__main {
      text-align: center;
      flex: 1;
    }

    &__download {
      margin: 20px 0 0;
    }

    &__downloadBtn {
      line-height: 1;
      height: auto;
      margin: 0;
      padding: 6px 20px;
      background: #fff;
      border: 1px solid #003705;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #003705;
      transition: .2s ease-out;

      &:hover,
      &:focus {
        background: #fff;
        color: #003705;
        opacity: .8;
      }
    }
  }

  .movieBlock {
    margin: 40px auto;

    &__inner {
      max-width: 560px;
      font-size: 16px;

      > p {
        margin: 0 0 5px;
        font-weight: bold;
      }
    }

    &__heading {
      span {
        display: inline-block;
        line-height: 1;
        padding: 3px 10px;
        background: #707070;
        color: #fff;
        white-space: nowrap;
      }
    }

    &__iframe {
      position: relative;
      width: 100%;
      height: 0;
      margin: 0 auto 0 0;
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }

  .personBlock {
    max-width: 820px;
    margin: 30px auto;

    &__inner {
      display: flex;
      align-items: flex-start;
    }

    &__left {
      width: 140px;

      span {
        position: relative;
        font-size: 16px;
        font-weight: bold;
        padding-left: 1em;

        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 7px);
          left: 0;
          width: 14px;
          height: 14px;
          border-radius: 90%;
          background: $c-text;
        }
      }
    }

    &__right {
      flex: 1;
    }

    &__list {
      display: flex;
      margin: 15px 0 0;
      padding-bottom: 15px;
      border-bottom: 1px solid #707070;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__image {
      width: 64px;
      margin-right: 15px;
      background: none;

      img {
        width: 100%;
      }
    }

    &__info {
      flex: 1;
      font-size: 12px;
    }

    &__note {
      display: flex;
      align-items: center;
    }

    &__guarantee {
      font-size: 16px;
    }

    &__adminNote {
      flex: 1;
      margin-left: 10px;
    }

    &__heading {
      display: flex;
      align-items: flex-end;
      line-height: 1;
      margin: 0 0 8px;
      padding-top: 10px;

      span {
        display: inline-block;
        font-size: 16px;
      }

      div {
        margin-left: 10px;
      }
    }
  }

  .adminNoteBlock {
    max-width: 820px;
    margin: 30px auto 0;

    &__inner {
      display: flex;
      align-items: flex-start;
      font-size: 16px;
    }

    &__left {
      width: 140px;

      span {
        position: relative;
        font-size: 16px;
        font-weight: bold;
        padding-left: 1em;

        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 7px);
          left: 0;
          width: 14px;
          height: 14px;
          border-radius: 90%;
          background: $c-text;
        }
      }
    }

    &__right {
      flex: 1;
    }
  }
}
