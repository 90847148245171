@charset "utf-8";

#page-top {
  padding: 0 0 150px;

  .listHead {
    margin: 20px 0;

    &__inner {
      position: relative;
    }

    &__conditions {
      padding-top: 10px;
      padding-right: 100px;

      p {
        display: flex;

        span {
          display: inline-block;
          font-size: 16px;

          &:nth-child(1) {
            margin-right: 6px;
          }

          &:nth-child(2) {
            flex: 1;
          }
        }
      }
    }

    &__count {
      margin: 20px 0 0;

      p {
        display: flex;
        align-items: flex-end;
        line-height: 1;
      }

      span {
        display: inline-block;

        &:nth-child(1) {
          margin-right: 6px;
          padding-bottom: 2px;
          font-size: 16px;
        }

        &:nth-child(2) {
          flex: 1;
          font-size: 30px;
          font-weight: bold;
        }
      }
    }

    &__changeView {
      position: absolute;
      top: 0;
      right: 5px;
      display: flex;

      a {
        position: relative;
        display: inline-block;

        &:nth-child(n+2) {
          margin-left: 15px;
        }

        &.-active {
          &:before {
            content: "";
            position: absolute;
            top: -5px;
            left: -5px;
            right: -5px;
            bottom: -5px;
            border: 1px solid #707070;
            pointer-events: none;
          }
        }
      }
    }

    &__changeSort {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;

      > span {
        position: relative;
        line-height: 1;
        padding-left: 22px;
        font-size: 16px;

        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 7px);
          left: 0;
          background-color: #707070;
          width: 18px;
          height: 14px;
          clip-path: polygon(0 0, 100% 0%, 50% 100%);
        }
      }

      > div {
        line-height: 1;
      }

      select {
        appearance: none;
        vertical-align: top;

        &::-ms-expand {
          display: none;
        }

        height: auto;
        margin: 0;
        padding: 0;
        border: none;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .list {
    margin: 30px 0 0;

    .item {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 0 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #707070;
      font-size: 12px;

      &__check {
        width: 36px;
        margin: 0;

        input {
          display: block;
          margin: 0 auto 0 0;
          width: 24px;
          height: 24px;
          opacity: 0;
          cursor: pointer;
        }

        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 17px);
          left: 0;
          width: 24px;
          height: 24px;
          border: 1px solid #707070;
          background: #fff;
          pointer-events: none;
        }

        &:after {
          display: none;
          content: '';
          position: absolute;
          top: calc(50% - 9px);
          left: 7px;
          width: 12px;
          height: 6px;
          border-left: 2px solid #707070;
          border-bottom: 2px solid #707070;
          transform: rotate(-45deg);
          pointer-events: none;
        }

        &.-check {
          &:before {
            background: #FCC800;
          }

          &:after {
            display: block;
          }
        }
      }

      &__checkText {
        display: none;
      }

      &__link {
        flex: 1;
        display: flex;
        align-items: center;

        transition: opacity .4s ease;

        &:hover {
          opacity: .8;
        }
      }

      &__image {
        position: relative;
        min-height: 80px;
        width: 64px;
        margin-right: 15px;
        background-size: cover;

        > span {
          position: relative;
          display: block;
          position: absolute;
          overflow: hidden;
          top: 0;
          right: 0;
          width: 20px;
          height: 20px;
          line-height: 20px;
          background: #fff;
          font-size: 12px;
          text-align: center;
          pointer-events: none;

          &:before {
            position: absolute;
            content: "\e40c";
            top: 50%;
            left: 50%;
            font-family: 'Material Icons';
            font-size: 18px;
            color: #707070;
            transform: translate(-50%, -50%);
          }

          &.-none {
            &:before {
              content: "\e5cd";
            }
          }
        }

        img {
          width: 100%;
          opacity: 0;
        }

        &--noimage {
          background-size: 100% 100%;
        }
      }

      &__info {
        flex: 1;
      }

      &__heading {
        display: flex;
        align-items: flex-end;
        line-height: 1;
        margin: 0 0 8px;
        padding-top: 10px;

        span {
          line-height: 1;
          display: inline-block;
          font-size: 16px;
        }

        div {
          margin-left: 10px;
        }
      }
    }

    &--type-box {
      display: flex;
      flex-wrap: wrap;
      margin: -20px -20px 0;

      @media #{$mq-sp} {
        margin: -20px -10px 0;
      }

      .item {
        display: block;
        width: calc(20% - 40px);
        margin: 20px 20px 0;
        border: none;

        @media #{$mq-pc} {
          min-width: 160px;
        }

        @media #{$mq-sp} {
          width: calc(50% - 20px);
          margin: 20px 10px 0;
        }
      }

      .item__link {
        position: relative;
        display: block;
      }

      .item__check {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        padding: 0;
        z-index: 11;

        input {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 24px;
          height: 24px;
          border: 1px solid #707070;
          background: #fff;
          pointer-events: none;
        }

        &:after {
          display: none;
          content: '';
          position: absolute;
          top: 8px;
          left: 7px;
          width: 12px;
          height: 6px;
          border-left: 2px solid #707070;
          border-bottom: 2px solid #707070;
          transform: rotate(-45deg);
          pointer-events: none;
        }

        &.-check {
          &:before {
            background: #FCC800;
          }

          &:after {
            display: block;
          }
        }
      }

      .item__heading {
        display: block;
        margin: 0;

        span {
          display: block;
          margin-bottom: 10px;
        }

        div {
          display: inline-block;
          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      .item__info {
        p {
          display: none;
        }
      }

      .item__image {
        width: 100%;
        height: 0;
        padding-bottom: calc(100% * 24 / 16);
        margin-right: 0;
        background: auto;
        background-size: cover;
        background-position: center;

        img {
          opacity: 0;
        }

        &--noimage {
          background-size: 100% 100%;
        }
      }
    }
  }

  .listMoreBtn {
    margin: 30px auto 0;
    text-align: center;

    &.-hide {
      display: none;
    }

    &__inner {
      position: relative;
      display: inline-block;

      &:before {
        content: "\e8b6";
        position: absolute;
        font-family: 'Material Icons';
        top: 50%;
        right: 22px;
        font-size: 28px;
        color: #003705;
        transform: translateY(-50%);
        backface-visibility: hidden;
        z-index: 100;
        pointer-events: none;
        transition: opacity .4s ease;
      }

      &:hover:before {
        opacity: .7;
      }
    }
  }

  #list-more {
    display: block;
    margin: 0 auto;
    padding-right: 40px;

    &.-hide {
      display: none;
    }
  }

  .fixedSelectedList {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#fff, .8);
    color: $c-text;
    pointer-events: none;
    opacity: 0;
    transition: opacity .4s ease;
    z-index: 11;

    &.-show {
      pointer-events: auto;
      opacity: 1;
    }

    &__inner {
      display: flex;
      align-items: center;
      padding: 15px 40px;

      p {
        flex: 1;
        font-size: 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 178px;
        height: 52px;
        margin: 0 0 0 20px;
        padding: 0;
        border: none;
        border-radius: 8px;
        background: #FCC800;
        font-size: 18px;
        font-weight: bold;
        color: #003705;
        transition: .2s ease-out;

        &:hover,
        &:focus {
          background: #FCC800;
          color: #003705;
          opacity: .7;
        }
      }
    }

    &__btn {
      position: relative;

      &:before {
        content: "\e8b6";
        position: absolute;
        font-family: 'Material Icons';
        top: 50%;
        right: 38px;
        font-size: 28px;
        color: #003705;
        transform: translateY(-50%);
        backface-visibility: hidden;
        z-index: 100;
        pointer-events: none;
        transition: opacity .4s ease;
      }

      &:hover:before {
        opacity: .7;
      }
    }
  }

  .sideMenu + .contents__main .fixedSelectedList {
    left: 300px;
  }
}
