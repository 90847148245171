@charset "utf-8";

body {
  overscroll-behavior: none;
}

.sideNav {
}

main {
  overflow: hidden;
}

.container {
  max-width: inherit;
  margin: 0;
  padding: 0;
}

.contents {
  padding: 20px;

  .sideMenu + &__main {
    margin-left: 300px;
    padding: 40px 50px;
    padding-bottom: 100px;

    .modal & {
      margin: 0;
      padding: 0;
    }
  }
}

.sideMenu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #003705;
  color: #fff;

  .modal & {
    display: none;
  }

  &__logo {
    padding: 30px;
  }

  &__edit {
    margin: 0 20px 30px;
  }

  &__editBtn {
    display: inline-block;
    line-height: 1;
    margin: 0;
    padding: 6px 15px;
    border: none;
    border-radius: 2px;
    background: #FCC800;
    text-align: center;
    transition: .2s ease-out;
    font-size: 14px;
    font-weight: bold;
    color: #003705;

    &:hover,
    &:focus {
      background: #FCC800;
      color: #003705;
      opacity: .8;
    }
  }

  &__delBtn {
    display: inline-block;
    line-height: 1;
    margin: 0;
    padding: 6px 15px;
    border: none;
    border-radius: 2px;
    background: red;
    text-align: center;
    transition: .2s ease-out;
    font-size: 14px;
    font-weight: bold;
    color: #fff;

    &:hover,
    &:focus {
      background: #fff;
      color: red;
      opacity: 1;
    }
  }

  &__heading {
    padding: 8px 20px 6px;
    border-top: 1px solid #fff;
    font-size: 24px;
    font-weight: bold;
    color: #FCC800;
  }

  &__list {
    li {
      position: relative;
      margin: 0;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        background: #fff;
      }

      &.-active a {
        color: #707070;
        pointer-events: none;
        text-decoration: none;

        span {
          &:before {
            display: none;
          }

          &:after {
            color: #707070;
          }
        }
      }
    }

    a {
      display: inline-block;
      margin: 0 20px;
      padding: 18px 10px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;

      span {
        position: relative;
        display: inline-block;
        padding-right: 24px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 12px;
          bottom: 2px;
          height: 1px;
          background: #fff;
        }

        &:after {
          content: "\e5df";
          position: absolute;
          font-family: 'Material Icons';
          top: 50%;
          right: 0;
          font-size: 28px;
          color: #fff;
          transform: translateY(-50%);
          backface-visibility: hidden;
          z-index: 100;
          pointer-events: none;
        }
      }
    }

    &:last-child li:last-child {
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        background: #fff;
      }
    }
  }

  &__logout {
    position: fixed;
    left: 20px;
    right: 20px;
    bottom: 20px;

    a {
      line-height: 1;
      font-size: 16px;
      color: #fff;

      span {
        position: relative;
        padding-right: 28px;

        &:before {
          content: "\e9ba";
          position: absolute;
          font-family: 'Material Icons';
          top: 50%;
          right: 0;
          font-size: 24px;
          color: fff;
          transform: translateY(-50%);
          backface-visibility: hidden;
          z-index: 100;
          pointer-events: none;
        }
      }
    }
  }
}

.message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.admin {
  font-size: 14px;

  .content {
    padding: 40px;
  }

  .side-nav {
    min-height: 100vh;
    padding: 20px;
    font-size: 16px;
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
  }

  .related {
    h4 {
      margin-top: 40px;
    }
  }

  .paginator {
    margin-top: 8px;
  }

  .table-responsive {
    td.nowrap {
      white-space: nowrap;
    }
  }

  .related {
    h4 {
      margin-bottom: 10px;
    }
  }
}

