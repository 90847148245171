@charset "utf-8";

#page-appearance {
  .listHead {
    margin: 20px 0;

    &__inner {
      position: relative;
    }

    &__conditions {
      padding-right: 100px;
    }

    &__count {
      margin: 20px 0 0;
    }

    &__changeView {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;

      a {
        display: inline-block;

        &:nth-child(n+2) {
          margin-left: 10px;
        }
      }
    }

    &__changeSort {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;

      > span {
        position: relative;
        padding-left: 22px;

        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 7px);
          left: 0;
          background-color: #707070;
          width: 18px;
          height: 14px;
          clip-path: polygon(0 0, 100% 0%, 50% 100%);
        }
      }

      select {
        appearance: none;
        &::-ms-expand {
          display: none;
        }

        height: auto;
        margin: 0;
        padding: 0;
        border: none;
        font-size: 19px;
      }
    }
  }

  .list {
    margin: 20px 0 0;

    .item {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 0 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #707070;
      font-size: 12px;

      &__link {
        flex: 1;
        display: flex;
        align-items: center;
        cursor: pointer;

        transition: opacity .4s ease;

        &:hover {
          opacity: .8;
        }
      }

      &__image {
        width: 132px;
        margin-right: 15px;
        background: none;

        img {
          width: 100%;
        }
      }

      &__info {
        flex: 1;
        font-size: 12px;
      }

      &__heading {
        display: flex;
        align-items: flex-end;
        line-height: 1;
        margin: 0 0 8px;
        padding-top: 10px;
      }

      &__projectName {
        font-size: 16px;
      }

      &__advertiser {
        margin-left: 10px;
      }

      &__orderSource {
        margin-left: 20px;
      }
    }
  }

  #list-more {
    display: block;
    margin: 50px auto 0;

    &.-hide {
      display: none;
    }
  }
}
