@charset "utf-8";

// ------------------------------------------------------
// Fonts
// ------------------------------------------------------
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:400,700&display=swap");

$font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "YuGothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo , sans-serif;

@mixin fontEn() {
  font-family: 'Work Sans', sans-serif;
}

// ------------------------------------------------------
// 変数
// ------------------------------------------------------
$c-text: #707070;
$c-white: #fff;
$c-red: #FF0000;
$c-link: #2f85ae;

$root-font-size: 19px;
$root-font-size-sp: 19px;

$root-line-height: 1.68;
$root-line-height-sp: 1.68;

// ------------------------------------------------------
// Media Queries
// ------------------------------------------------------

$mq-sp : "only screen and (max-width: 767px)";
$mq-pc : "print, (min-width: 768px)";

// ------------------------------------------------------
// Utilities
// ------------------------------------------------------

@media #{$mq-pc} {
  .forSp {
    display: none !important;
  }
}

@media #{$mq-sp} {
  .forPc {
    display: none !important;
  }
}

